<template>
  <div class="main-content">
    <b-card>
      <good-data-table 
        ref="holidays" 
        :columns="columns" :no-serial="true"
        :total-column="'response.responseData.total'" 
        :data-column="'response.responseData.data'"
        :api-endpoint="'/hrm/holiday'"
        @add-new-info="New_Holiday" 
        :buttonLabel="'Add'"
      />
      <validation-observer ref="Create_Holiday">
        <b-modal hide-footer size="md" v-model="Modal_Holiday" :title="editmode?$t('Edit'):$t('Add')">
          <b-form @submit.prevent="Submit_Holiday">
            <b-row>
                <!-- title -->
                <b-col md="12">
                  <validation-provider
                    name="title"
                    :rules="{ required: true}"
                    v-slot="validationContext"
                  >
                    <b-form-group :label="$t('Title') + ' ' + '*'">
                      <b-form-input
                        :placeholder="$t('Enter title')"
                        :state="getValidationState(validationContext)"
                        aria-describedby="title-feedback"
                        label="title"
                        v-model="holiday.title"
                      ></b-form-input>
                      <b-form-invalid-feedback id="title-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- start date -->
                <b-col md="12">
                  <validation-provider
                    name="start_date"
                    :rules="{ required: true}"
                    v-slot="validationContext"
                  >
                      <b-form-group :label="$t('Start Date') + ' ' + '*'">
                          <Datepicker id="start_date" name="start_date" :placeholder="$t('Enter Start date')" v-model="holiday.start_date" 
                              input-class="form-control back_important" format="yyyy-MM-dd"  @closed="holiday.start_date=formatDate(holiday.start_date)">
                          </Datepicker>
                          <b-form-invalid-feedback id="start_date-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- end date -->
                <b-col md="12">
                  <validation-provider
                    name="Finish_Date"
                    :rules="{ required: true}"
                    v-slot="validationContext"
                  >
                      <b-form-group :label="$t('Finish Date') + ' ' + '*'">
                          <Datepicker id="end_date" name="end_date" :placeholder="$t('Enter Finish date')" v-model="holiday.end_date" 
                              input-class="form-control back_important" format="yyyy-MM-dd"  @closed="holiday.end_date=formatDate(holiday.end_date)">
                          </Datepicker>
                          <b-form-invalid-feedback id="end_date-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Please_provide_any_details -->
              <b-col md="12">
                  <b-form-group :label="$t('Please provide any details')">
                    <b-form-textarea
                      rows="3"
                      :placeholder="$t('Please provide any details')"
                      label="description"
                      v-model="holiday.description"
                    ></b-form-textarea>
                  </b-form-group>
              </b-col>


              <b-col md="12" class="mt-3">
                  <b-button variant="primary" type="submit"  :disabled="SubmitProcessing"><i class="i-Yes me-2 font-weight-bold"></i> {{$t('submit')}}</b-button>
                    <div v-once class="typo__p" v-if="SubmitProcessing">
                      <div class="spinner sm spinner-primary mt-3"></div>
                    </div>
              </b-col>

            </b-row>
          </b-form>
        </b-modal>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import Vue from 'vue'
import axios from 'axios'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
const Fire = new Vue();
const apiUrl = `${jwtDefaultConfig.apiEndpoint}/hrm`;
import { hideLoader, showErrorNotification, showLoader, showSuccessNotification} from '@/@core/comp-functions/ui/app'

export default {
  metaInfo: {
    title: "Holiday"
  },
   components: {
    Datepicker,
    GoodDataTable
  },
  data() {
    return {
      isLoading: true,
      SubmitProcessing:false,
      Modal_Holiday: false,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "id",
          type: "desc"
        },
        page: 1,
        perPage: 10
      },
      data: new FormData(),
      selectedIds: [],
      totalRows: "",
      search: "",
      limit: "10",
      editmode: false,
      companies:[],
      holidays: {}, 
      holiday: {
          title: "",
          start_date:"",
          end_date:"",
          description:"",
      }, 
    };
  },

  computed: {
    columns() {
      return [
        {
          label: this.$t("Holiday"),
          field: "title",
          
        },
        {
          label: this.$t("Start Date"),
          field: "start_date",
          
        },
        {
          label: this.$t("Finish Date"),
          field: "end_date",
          
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('edit'),
              text: 'Edit',
              action: props => {
                this.Edit_Holiday(props)
              },
            },
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('delete'),
              text: 'Delete',
              action: props => {
                this.$swal({
                  title: this.$t('You want to delete?'),
                  iconHtml: this.$helpers.swalIcon('alert.png'),
                  showCancelButton: true,
                  confirmButtonText: this.$t('Yes'),
                  cancelButtonText: this.$t('No'),
                  customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-1',
                    icon: 'border-0',
                  },
                  buttonsStyling: false,
                }).then(result => {
                  if (result.value) {
                    this.Remove_Holiday(props)
                  }
                })
              },
            },
          ],
        },
      ];
    }
  },

  methods: {

    //---- Validation State Form
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    formatDate(d){
        var m1 = d.getMonth()+1;
        var m2 = m1 < 10 ? '0' + m1 : m1;
        var d1 = d.getDate();
        var d2 = d1 < 10 ? '0' + d1 : d1;
        return [d.getFullYear(), m2, d2].join('-');
    },
  

    //------------- Submit Validation Create & Edit Holiday
    Submit_Holiday() {
      this.$refs.Create_Holiday.validate().then(success => {
        if (!success) {
          showErrorNotification(this, 'Please fill the form correctly');
        } else {
          if (!this.editmode) {
            this.Create_Holiday();
          } else {
            this.Update_Holiday();
          }
        }
      });
    },

   //------------------------------ Show Modal (Create Holiday) -------------------------------\\
    New_Holiday() {
        this.reset_Form();
        this.editmode = false;
        this.Get_Data_Create();
        this.Modal_Holiday = true;
    },

    //------------------------------ Show Modal (Update Holiday) -------------------------------\\
    Edit_Holiday(holiday) {
        this.editmode = true;
        this.reset_Form();
        this.Get_Data_Edit(holiday.id);
        this.holiday = holiday;
        this.Modal_Holiday = true;
    },

     //---------------------- Get_Data_Create  ------------------------------\\
      Get_Data_Create() {
          axios
              .get(apiUrl+"/holiday/create")
              .then(response => {
                  this.companies   = response.data.companies;
              })
              .catch(error => {
                  
              });
      },

      //---------------------- Get_Data_Edit  ------------------------------\\
      Get_Data_Edit(id) {
        axios
             .get(`${apiUrl}/holiday/${id}/edit`)
            .then(response => {
                this.companies   = response.data.companies;
            })
            .catch(error => {
                
            });
    },

       Selected_Company(value) {
          if (value === null) {
          }
      },



    //--------------------------Get ALL holidays ---------------------------\\

    Get_Holidays(page) {
      // Start the progress bar.
      axios
        .get(
          apiUrl+"/holiday?page=" +
            page +
            "&SortField=" +
            this.serverParams.sort.field +
            "&SortType=" +
            this.serverParams.sort.type +
            "&search=" +
            this.search +
            "&limit=" +
            this.limit
        )
        .then(response => {
          this.totalRows = response.data.totalRows;
          this.holidays = response.data.holidays;

          // Complete the animation of theprogress bar.
          this.isLoading = false;
        })
        .catch(response => {
          // Complete the animation of theprogress bar.
          setTimeout(() => {
            this.isLoading = false;
          }, 500);
        });
    },

    //------------------------------- Create holiday ------------------------\\
    Create_Holiday() {
      
        var self = this;
        self.SubmitProcessing = true;
        axios.post(apiUrl+"/holiday", {
            title: self.holiday.title,
            start_date: self.holiday.start_date,
            end_date: self.holiday.end_date,
            description: self.holiday.description,
        }).then(response => {
            this.SubmitProcessing = false;
            this.Modal_Holiday = false;
            this.$refs.holidays.loadItems();
            showSuccessNotification(this, 'Created successfully!');
        })
        .catch(error => {
          this.SubmitProcessing = false;
          showErrorNotification(this, "Invalid Data")
        });
    },

    //------------------------------- Update holiday ------------------------\\
    Update_Holiday() {

      var self = this;
      self.SubmitProcessing = true;
      axios.put(apiUrl+"/holiday/" + self.holiday.id, {
          title: self.holiday.title,
          start_date: self.holiday.start_date,
          end_date: self.holiday.end_date,
          description: self.holiday.description,
      }).then(response => {
            this.SubmitProcessing = false;
            this.Modal_Holiday = false;
            this.$refs.holidays.loadItems();
            showSuccessNotification(this, 'Updated successfully!');
        })
        .catch(error => {
          this.SubmitProcessing = false;
          showErrorNotification(this, "Invalid Data")
        });
    },

    //------------------------------- reset Form ------------------------\\
    reset_Form() {
     this.holiday = {
        id: "",
        title: "",
        start_date:"",
        end_date:"",
        description:"",
    };
    },

    //------------------------------- Delete holiday ------------------------\\
    Remove_Holiday(props) {
      axios
        .delete(apiUrl+"/holiday/" + props.id)
        .then(() => {
          showSuccessNotification(this, 'Deleted successfully!');
          this.$refs.holidays.loadItems();
        })
        .catch(() => {
          showErrorNotification(this, "Something went wrong while deleting item!");
        });
    },

    //---- Delete department by selection

    delete_by_selected() {
      this.$swal({
        text: '',
        title: this.$t('Please confirm you want to delete this item?'),
        iconHtml: this.$helpers.swalIcon('alert.png'),
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
          icon: 'border-0',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          // Start the progress bar.
          axios
            .post(apiUrl+"/holiday/delete/by_selection", {
              selectedIds: this.selectedIds
            })
            .then(() => {
              showSuccessNotification(this, 'Deleted successfully!');

              Fire.$emit("Delete_Holiday");
            })
            .catch(() => {
              // Complete the animation of theprogress bar.
              showErrorNotification(this, "Something went wrong while deleting item!");
            });
        }
      });
    }
  },

  //----------------------------- Created function-------------------\\

  mounted: function() {
    this.$refs.holidays.loadItems();
  }
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
